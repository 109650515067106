body {
  background-color: #FFF5E9;
}

#root {
  padding: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.image-container {
  padding-left: 64px;
  flex: 3;
  position: relative; /* Added for the hover effect positioning */
  display: flex;
  flex-direction: column; /* Ensure images stack vertically */
}

.image-container img {
  width: 100%;
  max-width: 600px; /* Adjust max width for better responsiveness */
  cursor: pointer;
  position: relative; /* Added for positioning the pseudo-element */
  margin-bottom: 16px; /* Space between images */
  border-radius:24px;
}

/* Cursor change for hover */
.image-container img:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='20' viewBox='0 0 50 20'%3E%3Ctext x='0' y='15' font-family='DM Mono, monospace' font-size='15' font-weight='500' fill='%231E1E1E'%3Enext%3C/text%3E%3C/svg%3E"), auto;
}

.nav {
  flex: 1;
  position: relative; /* Ensure the hamburger icon stays within the nav */
  max-width: 500px;
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1001; /* Ensure the hamburger is above the menu */
}

.hamburger.hidden {
  display: none;
}

.nav-menu {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  display: flex;
  flex-direction: column;
}

.nav a:hover{
  font-weight: bold;
}

.nav a {
  text-decoration: none;
  color: #1e1e1e;
  font-style: normal; /* Default font style */
}

.sub-menu {
  list-style-type: none; /* Ensure nested lists also have no bullet points */
  max-height: 0; /* Initially collapsed */
  overflow: hidden; /* Hide overflowing content */
  transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out; /* Smooth transition */
  padding-left: 16px;
}

.sub-menu.expanded {
  max-height: 500px; /* Arbitrary large value to accommodate content */
  padding-top: 2px; /* Add padding for smooth transition */
  padding-bottom: 2px; /* Add padding for smooth transition */
}

.divider {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 16px 0;
}

.selected {
  font-weight: 500 !important;
  font-style: italic !important;
  /* color: #1e1e1e !important; */
}

.selected-album a {
  font-weight: 500 !important;
  font-style: italic !important;
  /* color: #99A374 !important; */
}

.logo img {
  width: 100%;
  padding-bottom: 32px;
}

.nav-active {
  font-weight: 500;
}

.nav ul li {
  font-size: 24px;
  line-height: 40px;
  padding-bottom: 0px;
  font-family: "DM Mono", monospace;
  font-weight: 400;
  font-style: normal;
  display: block; /* Ensure each list item is a block */
}

.small{
  font-size: 16px !important;
  line-height: 24px !important;
  padding-bottom: 0;
}

.nav ul li a {
  display: flex;
  align-items: center;
  gap: 16px; /* Space between chevron and text */
}

.nav ul li ul li {
  padding-left: 16px;  
}

.nav ul li:hover {
  font-weight: 500;
  font-style: italic;
}

.about {
  text-align: center;
  font-family: "DM Mono", monospace;
  font-weight: 400;
}

.about h2 {
  margin-bottom: 16px;
}

.about p {
  font-size: 16px;
  line-height: 1.5;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  #root {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    padding-left: 0;
    padding-top: 16px;
    display: block; /* Ensure images stack vertically */
  }

  .nav {
    width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    position: fixed; /* Fixed position to cover full screen */
    top: 0; /* Start at the top */
    left: 0; /* Start at the left */
    right: 0; /* Extend to the right */
    bottom: 0; /* Extend to the bottom */
    background-color: #FFF5E9;
    padding: 32px; /* 32px margin on all sides */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure the menu is above other content */
    overflow-y: auto; /* Allow scrolling within the menu */
  }

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 30px;
    cursor: pointer;
    display: block; /* Ensure the close button is displayed */
  }

  .nav ul li {
    padding-bottom: 0;
    width: 100%;
  }
}

/* Hide the close button on desktop */
@media (min-width: 769px) {
  .close {
    display: none; /* Hide the close button */
  }
}

.nav ul li {
  padding-bottom: 4px;
  width: 100%;
}

@media (max-width: 768px) {
  .image-container {
    display: block;
  }

  .divider {
    display: block;
    width: 100%;
  }
}
